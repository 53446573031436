// import image
import newsPageAnimationImage from "../../assets/images/newsPageAnimationImage.png";
// import style css
import "./News.scss";
import { Link, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
// import ScrollReveal
import ScrollReveal from "scrollreveal";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";
import urls from "../../ApiValues/urls";
import ReactPaginate from "react-paginate";
import LoadingComponent from "../../Components/LoadingComp/LoadingComponent";

function News() {
    const { lang } = useContext(TrContext);
    const [newsData, setNewsData] = useState([]);
    const [pageCount, setPageCount] = useState(2);
    const [isLoaded, setisLoaded] = useState(false);   
    let   [searchParams, setSearchParams] = useSearchParams();
    const initialPage = searchParams.get("page") || 1;
    const [currentPage, setcurrentPage] = useState(initialPage);

    const handlePageChange = (selectedObject) => {
        const pageNumber = Number(selectedObject.selected) + 1;
        setSearchParams({ ...searchParams, page: pageNumber });   
        setcurrentPage(pageNumber);
    };
    
    const loadImages = async (data) => {
		const imagePromises = [newsPageAnimationImage];
        data.forEach(key => {
            let imageSrc = key.cover_image;
            let imageUrl = (`${looptech.baseUrlImage}${imageSrc}`)
			const image = new Image();
			const promise = new Promise((resolve, reject) => {
				image.onload = () => resolve(imageUrl);  
				image.onerror = () => reject(imageUrl);
			});
			image.src = imageUrl;
			imagePromises.push(promise);
		});
	
		await Promise.all(imagePromises)
			.then(() => {
				console.log('loaded');
			})
			.catch((err) => {
				console.log('Error -- ', err);
			});
    }    
    useEffect(() => {
        // setisLoaded(false);
        let currentPage = searchParams.get("page") || 1;
        setcurrentPage(currentPage);
        const getNewsDatas = async () => {
            try {
                const newsDatasRes = await looptech.api().get(`${urls.news(lang)}&page=${currentPage}`);
                await loadImages(newsDatasRes.data.data.data);
                setPageCount(newsDatasRes.data.data.last_page);
                setNewsData(newsDatasRes.data.data.data);
                setisLoaded(true);
            } catch (error) {
                setisLoaded(true);
                console.log(error);
            }  
        };

        window.scrollTo(0, 0);
        getNewsDatas();
    }, [searchParams,lang]);

    useEffect(() => {
        if (newsData.length > 0)
            ScrollReveal().reveal(".news", {
                interval: 400,
            });
    }, [newsData]);

    return (
        <>
        { isLoaded ? (
            <section id="news">
                <div className="news-animation">
                    <div className="title-animation">
                        <h2 className="text-uppercase">{looptech.translate("news")}</h2>
                        <img className="animation-image" src={newsPageAnimationImage} loading="lazy" />
                    </div>
                </div>
                
                <div className="container">
                    <div className="tr-all-news">
                        {/* { isLoaded ?  */}   
                        {
                        newsData.map((item) => item.cover_image && (
                                  <Link to={`/news/${item.slug}`} key={item.id} className="news">
                                      <img className="newsImg" src={`${looptech.baseUrlImage}${item.cover_image}`}
                                        alt="burada xeber sekli var" loading="lazy" />
                                      <div className="newsMiniInfo">
                                          <h4>{item.title}</h4>
                                          <p dangerouslySetInnerHTML={{ __html: item.body.replace(/(<([^>]+)>)/gi, "").substring(0, 120) }}></p>
                                          <h6>{item.created_at}</h6>
                                          <span>{looptech.translate("readMore")}</span>
                                      </div>
                                  </Link>
                              ))
                        }
                            {/* : ""} */}
                    </div>
                    {/* {isLoaded ? ( */}
                        <ReactPaginate
                            initialPage={Number(currentPage) - 1}   
                            disableInitialCallback={true}
                            pageCount={pageCount}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            previousLinkClassName={"page"}
                            breakClassName={"page"}
                            nextLinkClassName={"page"}
                            pageClassName={"page"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            breakLabel="..."
                            nextLabel=">"
                            previousLabel="<"
                        />
                    {/* ) : (<LoadingComponent />)} */}
                    {/* )} */}
                </div>
            </section> 
            ) : (
            <LoadingComponent />
        )}
        </>
    );
}

export default News;
