import React, { useEffect } from "react";
// import style css
import "./Button.scss";
// import AOS
import Aos from "aos";
import "aos/dist/aos.css";

function ButtonComponent({ title, style }) {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    return (
        <>
            <button data-aos="fade-up" style={style}>
                {title}
            </button>
        </>
    );
}

export default ButtonComponent;
