import React from "react";
// import style css
import "./LoadingComponent.scss";
import loadinLogo from "../../assets/triathlonLogos/tr-white-logo.svg";

function LoadingComponent() {
    return (
        <div className="loader">
            <img className="loading-img" src={loadinLogo} alt="" />
            <div className="loader__element"> </div>
        </div>
    );
}

export default LoadingComponent;
  