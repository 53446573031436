import React from "react";
import { useEffect } from "react";
// import style css
import "./HowToStart.scss";
// import my write data
import { HowToStartInitial } from "../../MyWritesData/data";
import { HowToStartPopular } from "../../MyWritesData/data";
// import image
import howToStartImage from "../../assets/images/howToStart.jpg";
import InitialPopular from "../InitialAndPopularComponent/InitialAndPopular";

import Aos from "aos";
import "aos/dist/aos.css";
import { useContext } from "react";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";

function HowToStart() {
    useContext(TrContext);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        Aos.init({ duration: 8000 });
    }, []);

    return (
        <>
            <section id="how-to-start-section">
                <div className="container">
                    <div className="how-to-start">
                        <img data-aos="fade-up" data-aos-duration="1000" className="howToStartImage" src={howToStartImage} loading="lazy" alt="burada start shekli var" />
                        <div className="what-is-triathlon">
                            <h2 data-aos="fade-up" data-aos-duration="1000">
                                {looptech.translate("what-is-triathlon?")}
                            </h2>
                            <p data-aos="fade-up" data-aos-duration="1000">
                                {looptech.translate("what-is-description")}
                            </p>

                            <h5 data-aos="fade-up" data-aos-duration="1000">
                                {looptech.translate("tr-distances")}:
                            </h5>
                            <div className="initial-popular-format">
                                {HowToStartInitial.map((item) => (
                                    <div key={item.id} className="initial-popular-format-info">
                                        <InitialPopular header={looptech.translate(item.header) ? looptech.translate(item.header) : item.header} swimming_length={item.swimming_length} cycling_length={item.cycling_length} running_length={item.running_length} />
                                    </div>
                                ))}
                            </div>

                            <h5 data-aos="fade-up" data-aos-duration="1000">
                                {looptech.translate("tr-most-distances")}:
                            </h5>
                            <div className="initial-popular-format">
                                {HowToStartPopular.map((item) => (
                                    <div key={item.id} className="initial-popular-format-info">
                                        <InitialPopular header={looptech.translate(item.header) ? looptech.translate(item.header) : item.header} swimming_length={item.swimming_length} cycling_length={item.cycling_length} running_length={item.running_length} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HowToStart;
