const createUrl = (endpoint,lang,add)=>{
   return `${endpoint}?lang=${lang}${add?.length ? `&${add}` : ''}`;
}

const urls={
    events:lang => createUrl("/events",lang),
    results:lang => createUrl("/results",lang),     
    social: "/social",
    contactGet:"/contact",   
    contactPost:lang=>createUrl("/contact",lang),
    news:lang=>createUrl("/news",lang),   
    federationPresitentsAbout:lang=>createUrl("/about",lang),
    federationManagement:lang=>createUrl("/executives",lang),
    federationPresitentsImg:lang=>createUrl("/top_federations",lang),
    staff:lang=>createUrl("/staff",lang),  
    directors:lang=>createUrl("/directors",lang),
    clubs:lang=>createUrl("/clubs",lang),
    heroSliderVideo:lang=>createUrl("/hero-slider-video",lang),
    heroSliderAnualReport:lang=>createUrl("/hero-sliders",lang),
	homeNews:lang=>createUrl("/news",lang,"limit=3"),   
    multimedia:lang=>createUrl("/multimedia",lang),
    multimediaSingle:lang=>createUrl("/multimedia/:slug",lang),   
    register:"/register"
    }
	export default urls