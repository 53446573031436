import { Link } from "react-router-dom";
import { useEffect } from "react";
// import style css
import "./HomePageNews.scss";
// import  ScrollReveal
import ScrollReveal from "scrollreveal";
import looptech from "../../Helpers/looptech";
import { useContext } from "react";
import { TrContext } from "../../TrUseContext/TrUseContext";

function HomePageNews({ homeNews }) {
    useContext(TrContext);
    useEffect(() => {
        ScrollReveal().reveal(".home-news", {  
            interval: 1000,
        });
    }, []);

    //  console.log("home news", homeNews);
    return (
        <>
            <section id="home-news">
                <div className="container">
                    <div className="home-page-news">
                        {homeNews.map((news) => (
                            <Link to={`news/${news.slug}`} key={news.id} className="home-news">
                                <img src={`${looptech.baseUrlImage}${news.cover_image}`} loading="lazy" alt="burada xeber sekli var" />
                                <div className="HomeNewsMiniInfo">
                                    <h4>{news.title}</h4>
                                    <p dangerouslySetInnerHTML={{ __html: news.body.replace(/(<([^>]+)>)/gi, "") }}></p>
                                    <h6>{news.created_at}</h6>
                                    <span>{looptech.translate("readMore")}</span>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <Link className="btn-link" to="news">
                        {looptech.translate("readMore")}
                    </Link>
                </div>
            </section>
        </>
    );
}

export default HomePageNews;
