import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import sytle css
import "./LetsDoIt.scss";
// import  component
import ButtonComponent from "../ButtonComponent/Button";
// import image
import letsDoImage from "../../assets/images/letsDoItImage.png";
// import AOS
import Aos from "aos";
import "aos/dist/aos.css";
import looptech from "../../Helpers/looptech";

function LetsDoIt() {
    const RegstrButonStyle = {
        backgroundColor: "#1B3667",
        color: "#FFFFFF",
    };

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    return (
        <>
            <section id="lets-do-it">
                <div className="container">
                    <div className="registration-image">
                        <div className="registration">
                            {/* cemiponat vaxti gosterilmeli olan basliq */}
                            {/* <h2 data-aos="fade-up" className="homeRegister">
                                {looptech.translate("Az-championship")}
                            </h2> */}
                            {/* adi formada olan basliq*/}
                            <h2 style={{width:"100%"}} className="homeRegister" data-aos="fade-up">
                                {looptech.translate("ForRegistration")}
                            </h2>
                            <Link className="blue-btn" to="/registration">
                                <ButtonComponent style={RegstrButonStyle} title={`${looptech.translate("registration")}`} />
                            </Link>
                            <Link className="red-btn" to="/registration">
                                <ButtonComponent title={`${looptech.translate("registration")}`} />
                            </Link>
                        </div>
                        <div className="image">
                            <img src={letsDoImage} alt="..." loading="lazy" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default LetsDoIt;
