import React, { useContext, useState, useEffect } from "react";
import RotateButtonComp from "../RotateButtonComponent/RotateButtonComp";
import "./ExecutiveBoard.scss";
import ManagementAndStaffComponent from "../ManagementAndStaffComponent/ManagementAndStaffComponent";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";
// import ScrollReveal
import ScrollReveal from "scrollreveal";

function ExecutiveBoard({ ExecutiveBoard }) {
    const contextDataLang = useContext(TrContext).lang;
    const [executiveBoard, setExecutiveBoard] = useState(true);

    // console.log("managment com datas", ExecutiveBoard);

    const funcExecutiveBoard = () => {
        setExecutiveBoard(!executiveBoard);
    };

    useEffect(() => {
        ScrollReveal().reveal(".executiveBoard", {
            interval: 300,
            // reset: true
        });
    }, []);
    if (!executiveBoard) return;
    return (
        <>
            <div id="executiveBoard">
                <h2>{looptech.translate("ExecutiveBoard")}</h2>
                <div onClick={funcExecutiveBoard} className="rotate-btn-component">
                    <RotateButtonComp />
                </div>
                {ExecutiveBoard && (
                    <div className="tr-executiveBoard">
                        {ExecutiveBoard.map((item) => (
                            <div key={item.id} className="executiveBoard">
                                <ManagementAndStaffComponent keys={item.id} image={`${looptech.baseUrlImage}${item.image}`} name={item.title} position={item.position} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}

export default ExecutiveBoard;
