import React, { useState } from "react";
// import style scss
import "./RotateButtonComp.scss";
// import react icons
import { BsChevronCompactDown } from "react-icons/bs";

function RotateButtonComp() {
    const [rotate, setRotate] = useState(true);
    const rotateMove = rotate ? "rotate(0deg)" : "rotate(180deg)";
    const funcIconMove = () => {
        setRotate(!rotate);
    };
    return (
        <>
            <div onClick={funcIconMove} style={{ transform: rotateMove }} className="rotate-btn">
                <BsChevronCompactDown className="rotate-icon" />
            </div>
        </>
    );
}

export default RotateButtonComp;
