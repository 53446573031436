// import style css
import "./AthletesDetails.scss";
// import image
import AthletesDetailsHeaderImg from "../../assets/images/AthletesDetailsHeader.png";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import looptech from "../../Helpers/looptech";
import { TrContext } from "../../TrUseContext/TrUseContext";
import LoadingComponent from "../LoadingComp/LoadingComponent";

function AthletesDetails() {
    const { lang } = useContext(TrContext);
    const { slug } = useParams();
    const [loading, setLoading] = useState(true);

    const [athlete, setAthlete] = useState({});

    const getAthleteData = async () => {
        try {
            const reaAthleteData = await looptech.api().get(`/athletes/${slug}?lang=${lang}`);
            setAthlete(reaAthleteData.data.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAthleteData();
    }, [lang]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {loading ? (
                <LoadingComponent />
            ) : (
                <section id="athletesDetails">
                    <img className="header-img" src={AthletesDetailsHeaderImg} loading="lazy" alt="burada athlete header image var" />

                    <div className="container">
                        <div className="athletesDetailsWrapper">
                            <img className="athletDetailsImg" src={`${looptech.baseUrlImage}${athlete.image}`} alt={athlete.title} />
                            <div className="athletDetailsInfo">
                                <h4 className="athletDetailsTitle">{athlete.title}</h4>
                                <span className="athletDetailsBirthday">{athlete.birthday}</span>
                                <p className="athletDetailsDescription">{athlete.description}</p>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default AthletesDetails;
