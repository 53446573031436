// import swiper datas
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper";

// import style css
import "./ClubsSliderComponent.scss";
import looptech from "../../Helpers/looptech";

function ClubsSlideComponent({ clubsDatas }) {
    // console.log("componente gonderilen clubs datasi", clubsDatas);
    return (
        <>
            <div className="clubs-slide">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        1050: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                        940: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        550: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        400: {
                            slidesPerView: 1,
                            spaceBetween: 50,
                        },
                    }}
                    navigation={true}
                    modules={[Navigation, Autoplay]}
                    className="mySwiper"
                >
                    {clubsDatas.map((item) => (
                        <SwiperSlide key={item.id}>
                            <img src={`${looptech.baseUrlImage}${item.image}`} alt="burada club logosu var " />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    );
}

export default ClubsSlideComponent;
