import React, { useEffect, useContext } from "react";
// import style scss
import "./Contact.scss";
// import useFormik
import { useFormik } from "formik";
// import yup
import * as Yup from "yup";
// import sweetalert2
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Map from "../../Components/Map/Map";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";
import urls from "../../ApiValues/urls";

function Contact() {
    useContext(TrContext);

    const validationSchema = Yup.object({
        name: Yup.string().required("name-error"),
        email: Yup.string().email().required("email-error"),
        number: Yup.string().required("phone-error"),
        subject: Yup.string().required("subject-error"),
        message: Yup.string().required("textarea-error"),
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { handleChange, values, handleSubmit, errors, resetForm } = useFormik({
        initialValues: {
            name: "",
            email: "",
            number: "",
            subject: "",
            message: "",   
        },
        onSubmit: async (values) => {
            try {
                await looptech.api().post(urls.contactPost, JSON.stringify(values));
                const MySwal = withReactContent(Swal);
                MySwal.fire({
                    title: <strong>{looptech.translate("swalAlertTitle")}</strong>,
                    html: <i>{looptech.translate("swalAlertHTML")}</i>,
                    icon: "success",
                });
            } catch (error) {
                console.log(error);
                alert("error var !!!");
            }
            resetForm();
        },
        validationSchema,
    });
    return (
        <>
            <section id="contact">
                <div className="container">
                    <div className="map-form">
                        <div className="map">
                            <Map />
                        </div>  
                        <div className="form-group">
                            <h2 className="text-uppercase">{looptech.translate("contact")}</h2>
                            <form onSubmit={handleSubmit}>
                                <input name="name" type="text" placeholder={looptech.translate("name")} value={values.name} onChange={handleChange} />
                                <div className="error">{errors.name ? looptech.translate(errors.name) : null}</div>
                                <input name="email" type="email" placeholder={looptech.translate("email")} value={values.email} onChange={handleChange} />
                                <div className="error">{errors.email ? looptech.translate(errors.email) : null}</div>
                                <input name="number" type="number" placeholder={looptech.translate("phone")} value={values.number} onChange={handleChange} />
                                <div className="error">{errors.number ? looptech.translate(errors.number) : null}</div>
                                <input name="subject" type="text" placeholder={looptech.translate("subject")} value={values.subject} onChange={handleChange} />
                                <div className="error">{errors.subject ? looptech.translate(errors.subject) : null}</div>
                                <textarea name="message" rows="3" value={values.message} onChange={handleChange}></textarea>
                                <div className="error error-textarea">{errors.message ? looptech.translate(errors.message) : null}</div>
                                <button type="submit">{looptech.translate("send")}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;
