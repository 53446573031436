import React, { useContext, useState, useEffect } from "react";
import RotateButtonComp from "../RotateButtonComponent/RotateButtonComp";
import "./Management.scss";
import ManagementAndStaffComponent from "../ManagementAndStaffComponent/ManagementAndStaffComponent";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";
// import ScrollReveal
import ScrollReveal from "scrollreveal";

function Management({ managmentDatas }) {
    const contextDataLang = useContext(TrContext).lang;
    const [OpenShowManagement, setOpenShowManagement] = useState(true);

    // console.log("managment com datas",managmentDatas);

    const funcOpenShowManagment = () => {
        setOpenShowManagement(!OpenShowManagement);
    };

    useEffect(() => {
        ScrollReveal().reveal(".management", {
            interval: 300,
        });
    }, []);

    if (!managmentDatas) return;

    return (
        <>
            <div id="management">
                <h2>{looptech.translate("ExecutiveCommittee")}</h2>
                <div onClick={funcOpenShowManagment} className="rotate-btn-component">
                    <RotateButtonComp />
                </div>
                {OpenShowManagement && (
                    <div className="tr-managements">
                        {managmentDatas.map((item) => (
                            <div key={item.id} className="management">
                                <ManagementAndStaffComponent keys={item.id} image={item.image ? `${looptech.baseUrlImage}${item.image}` : "/deafultImg.svg"} name={item.title} position={item.position} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}

export default Management;
