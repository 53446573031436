import axios from "axios";
import languageJson from "../Components/LocaleLanguages/languages.json";

class LoopTech {
    constructor() {
        this.languageJson = languageJson;
        this.langsArr = ["az", "en", "ru"];
        this.language = localStorage.getItem("language") || "az";  
        this.baseUrl = "https://admin.triathlon.az/api";
        this.baseUrlImage = "https://admin.triathlon.az/storage/";
        this.headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
    }
    api() {
        return axios.create({
            baseURL: this.baseUrl,
            headers: this.headers,  
        });
    }

    translate(key) {
        return this.languageJson[this.language][key] || "";
    }
}
const looptech = new LoopTech();
export default looptech;
