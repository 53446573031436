import React from "react";
// import style css
import "./ManagementAndStaffComponent.scss";

function ManagementAndStaffComponent({ image, name, position }) {
    return (
        <div className="cart">
            <img src={image}  />
            <div className="datas">
                <h5>{name}</h5>
                <p>{position}</p>
            </div>
        </div>
    );
}

export default ManagementAndStaffComponent;
