import { useContext, useEffect } from "react";
// import style css
import "./History.scss";
// import history images
import TrHistory1 from "../../assets/images/tr-history1.svg";
import TrHistory2 from "../../assets/images/tr-history2.svg";
import TrHistory3 from "../../assets/images/tr-history3.svg";
// import AOS
import Aos from "aos";
import "aos/dist/aos.css";
import looptech from "../../Helpers/looptech";
import { TrContext } from "../../TrUseContext/TrUseContext";

function History() {
    useContext(TrContext);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        Aos.init({ duration: 8000 });
    }, []);
    return (
        <>
            <section id="tr-history">
                <div className="container">
                    <div className="history">
                        <div className="top-history">
                            <img data-aos="fade-up" data-aos-duration="700" src={TrHistory1} alt="burada triathlonun tarixine aid shekil var" />
                            <div className="tr-about-history">
                                <h2 data-aos="fade-up" data-aos-duration="1500">
                                    {looptech.translate("tr-history-title")}
                                </h2>
                                <p data-aos="fade-up" data-aos-duration="1500">
                                    {looptech.translate("tr-history-description-1")}
                                </p>
                                <p data-aos="fade-up" data-aos-duration="1500">
                                    {looptech.translate("tr-history-description-2")}
                                </p>
                                <p data-aos="fade-up" data-aos-duration="1500">
                                    {looptech.translate("tr-history-description-3")}
                                </p>
                                <p data-aos="fade-up" data-aos-duration="1500">
                                    {looptech.translate("tr-history-description-4")}
                                </p>
                            </div>
                        </div>
                        <div className="bottom-history">
                            <img data-aos="fade-up" data-aos-duration="700" src={TrHistory2} alt="burada triathlonun tarixine aid shekil var" />
                            <img data-aos="fade-up" data-aos-duration="1500" src={TrHistory3} loading="lazy" alt="burada triathlonun tarixine aid shekil var" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default History;
