import { createContext, useEffect, useState } from "react";
import looptech from "../Helpers/looptech";
import { useParams } from "react-router";

export const TrContext = createContext();

export const GlobalProvider = (props) => {
    let { locale } = useParams();
    const defaultLang = locale || localStorage.getItem("language") || "az";
    const [lang, setLang] = useState(defaultLang);
    looptech.language = lang;
    document.documentElement.setAttribute("lang", lang);

    const changeLanguage = (lang) => {
        setLang((ln) => {
            if (ln == lang) return lang;
            looptech.language = lang;
            localStorage.setItem("language", lang);
            document.documentElement.setAttribute("lang", lang);
            window.history.pushState({}, "", window.location.pathname.replace(ln, lang));
            return lang;
        });
    };

    useEffect(() => {
        changeLanguage(defaultLang);   
    }, []);

    return <TrContext.Provider value={{ lang, changeLanguage }}>{props.children}</TrContext.Provider>;
};
