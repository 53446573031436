import React, { useState, useEffect } from "react";
// style scss import
import "./Home.scss";
import StartAndHistory from "../../Components/History&howToStart/StartHistory";
import Slider from "../../Components/SliderSection/Slider";
import LetsDoIt from "../../Components/LetsDoIt/LetsDoIt";
import HomePageNews from "../../Components/HomePageNews/HomePageNews";
import looptech from "../../Helpers/looptech";
import urls from "../../ApiValues/urls";
import LoadingComponent from "../../Components/LoadingComp/LoadingComponent";
import { useContext } from "react";
import { TrContext } from "../../TrUseContext/TrUseContext";

function HomePage() {
    const { lang } = useContext(TrContext);
    const [heroSliderVideo, setHeroSliderVideo] = useState("");    
    const [heroSliderAnualReport, setHeroSliderAnualReport] = useState([]);
    const [homeNews, setHomeNews] = useState([]);
    const [Loading, setLoading] = useState(true);

    useEffect(() => {
        const HomePageDatas = async () => {
            try {
                const [videoData, anulaData, homeNewsData] = await Promise.all([
                    looptech.api().get(urls.heroSliderVideo(lang)),
                    looptech.api().get(urls.heroSliderAnualReport(lang)),
                    looptech.api().get(urls.homeNews(lang))]);
                setHeroSliderVideo(videoData.data.data);
                setHeroSliderAnualReport(anulaData.data.data);
                setHomeNews(homeNewsData.data.data);    
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        HomePageDatas();
    }, [lang]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {Loading ? (
                <LoadingComponent />
            ) : (
                <div className="home-page">
                    <Slider video={heroSliderVideo} anualRepo={heroSliderAnualReport} />
                    <StartAndHistory />
                    <LetsDoIt />
                    <HomePageNews homeNews={homeNews} />
                </div>
            )}
        </>
    );
}

export default HomePage;
