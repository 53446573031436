export const HowToStartInitial = [
    {
        id: 1,
        header: "xs",
        swimming_length: "400 m",
        cycling_length: "10000 m",
        running_length: "2500 m",
    },
    {
        id: 2,
        header: "XXXL (Ironman)",
        swimming_length: "1500 m",
        cycling_length: "70000 m",
        running_length: "7500 m",
    },
];

export const HowToStartPopular = [
    {
        id: 1,
        header: "Classic",
        swimming_length: "3 800 m",
        cycling_length: "180 000 m",
        running_length: "42 200 m",
    },
    {
        id: 2,
        header: "Semi Classic",
        swimming_length: "1 900 m",
        cycling_length: "90 000 m",
        running_length: "21 000 m",
    },
    {
        id: 3,
        header: "Olympics",
        swimming_length: "1 500 m",
        cycling_length: "40 000 m",
        running_length: "10 000 m",
    },
    {
        id: 4,
        header: "Sprint",
        swimming_length: "750 m",
        cycling_length: "20 000 m",
        running_length: "5 000 m",
    },
    {
        id: 5,
        header: "Super Sprint",
        swimming_length: "375 m",
        cycling_length: "10 000 m",
        running_length: "2 500 m",
    },
];
