import React from "react";
import { useEffect } from "react";
// import style css
import "./InitialAndPopular.scss";
// import svg icon
import iconWavyLine from "../../assets/images/icon-vawyLine.svg";
import iconCircle from "../../assets/images/icon-circle.svg";
import iconDiognal from "../../assets/images/icon-diognal.svg";

// import AOS
import Aos from "aos";
import "aos/dist/aos.css";
import { useContext } from "react";
import { TrContext } from "../../TrUseContext/TrUseContext";

function InitialAndPopular({ header, swimming_length, cycling_length, running_length }) {
    useContext(TrContext);
    useEffect(() => {
        Aos.init({ duration: 8000 });
    }, []);

    return (
        <>
            <div data-aos="fade-up" data-aos-duration="2000">
                <h6>{header}</h6>
                <div className="svgIcon-length">
                    <img src={iconWavyLine} alt="Burada əyri xətt formasinda svg var" />
                    <span>{swimming_length}</span>
                </div>
                <div className="svgIcon-length">
                    <img src={iconCircle} alt="Burada dairə formasinda svg var" />
                    <span>{cycling_length}</span>
                </div>
                <div className="svgIcon-length">
                    <img src={iconDiognal} alt="Burada diaqnal formasinda svg var" />
                    <span>{running_length}</span>
                </div>
            </div>
        </>
    );
}

export default InitialAndPopular;
