import { React, useEffect } from "react";
import { Link } from "react-router-dom";
// import style scss
import "./StartHistory.scss";
// import image
import StartImage from "../../assets/images/Tr-start.png";
import HistoryImage from "../../assets/images/history .png";
// import component
import ButtonComponent from "../ButtonComponent/Button";
// import AOS
import Aos from "aos";
import "aos/dist/aos.css";
import { useContext } from "react";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";

function StartAndHistory() {
    useContext(TrContext);
    useEffect(() => {
        Aos.init({ duration: 8000 });
    }, []);

    return (
        <>
            <section id="start-history-section">
                <div className="container">
                    <div className="start-history">
                        <div className="start">
                            <img loading="lazy" data-aos="fade-up" data-aos-duration="1000" src={StartImage} alt="..." />

                            <div className="info">
                                <h4 data-aos="fade-up" data-aos-duration="1000">
                                    {looptech.translate("how-to-start")}
                                </h4>
                                <p data-aos="fade-up" data-aos-duration="1000">
                                    {looptech.translate("how-to-get-started")}
                                </p>
                                <Link to="how-to-start">
                                    <ButtonComponent title={`${looptech.translate("readMore")}`} />
                                </Link>
                            </div>
                        </div>
                        <div className="history">
                            <img data-aos="fade-up" data-aos-duration="8000" loading="lazy" className="history-img" src={HistoryImage} alt="..." />
                            <div className="info">
                                <h4 data-aos="fade-up" data-aos-duration="8000">
                                    {looptech.translate("tr-history-title")}
                                </h4>
                                <p data-aos="fade-up" data-aos-duration="8000">
                                    {looptech.translate("tr-from-today")}
                                </p>
                                <Link to="history">
                                    <ButtonComponent title={`${looptech.translate("readMore")}`} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default StartAndHistory;
