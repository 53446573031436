import { useContext, useEffect, useState } from "react";
// import style css
import "./Events.scss";
// import ScrollReveal
import ScrollReveal from "scrollreveal";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";
import urls from "../../ApiValues/urls";
import LoadingComponent from "../../Components/LoadingComp/LoadingComponent";

function Events() {
    const { lang } = useContext(TrContext);
    const [EventsDatas, setEventsDatas] = useState([]);
    const [loading, setLoading] = useState(true);

    //   api events page
    const getEventsDatas = async () => {
        try {
            const eventsDatasRes = await looptech.api().get(urls.events(lang));   
            setEventsDatas(eventsDatasRes.data.data);    
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getEventsDatas();
    }, [lang]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if (EventsDatas.length > 0)
            ScrollReveal().reveal(".table-mobile .table", {
                interval: 500,
            });
    }, [EventsDatas]);

    // console.log("events datas", EventsDatas);

    return (
        <>
            {loading ? (
                <LoadingComponent />
            ) : (
                <section id="events">
                    <div className="container">
                        <h2>{looptech.translate("events").toUpperCase()}</h2>
                        <div className="table-web">
                            <ul>
                                <li className="event">Event</li>
                                <li className="distance">Distance</li>
                                <li className="date">Date</li>
                            </ul>
                            <hr />
                            {EventsDatas.map((item) => (
                                <div key={item.id} className="table-date">
                                    <h5 className="event" title={item.title}>
                                        {item.title}
                                    </h5>
                                    <p className="distance">{item.distance}</p>

                                    <p className="date">{item.date}</p>
                                </div>
                            ))}
                            <hr />
                        </div>

                        <div className="table-mobile">
                            {EventsDatas.map((item) => (
                                <div key={item.id} className="table">
                                    <ul>
                                        <li>Event</li>
                                        <li>Distance</li>
                                        <li>Date</li>
                                    </ul>
                                    <div className="table-date">
                                        <h5 title={item.title}>{item.title}</h5>
                                        <p style={{ height: "1lh" }}>{item.distance}</p>
                                        <p className="date">{item.date}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default Events;
