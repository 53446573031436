import React, { useContext, useRef } from "react";
// import styel css
import "./Slider.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import looptech from "../../Helpers/looptech";
import { TrContext } from "../../TrUseContext/TrUseContext";

function Slider({ video, anualRepo }) {
    useContext(TrContext);
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.width = `${(1 - progress) * 100}%`;
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };

    return (
        <>
            <section id="slider">
                <div className="container">
                    <video autoPlay loop muted className="video-background" loading="lazy">
                        <source src={`${looptech.baseUrlImage}hero_sliders/${JSON.parse(video)[0].original_name}`} type="video/mp4" loading="lazy" />
                    </video>
                </div>
                <div className="container">
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: true,
                        }}
                        pagination={{
                            type: "fraction",
                        }}
                        navigation={true}
                        loop={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        onAutoplayTimeLeft={anualRepo.length > 1 ? onAutoplayTimeLeft : null}
                        className="mySwiper"
                    >
                        {anualRepo.map((item) => (
                            <SwiperSlide key={item.id}>
                                <p className="date">{item.title}</p>
                                <div className="text-btn">
                                    <p className="text">{item.description}</p>
                                    <a href={item.url}>{item.button_text ?? `${looptech.translate("download")}`}</a>
                                </div>
                            </SwiperSlide>
                        ))}
                        {anualRepo.length > 1 ? (
                            <div className="autoplay-progress" slot="container-end">
                                <div className="line" ref={progressCircle}></div>
                                <span ref={progressContent}></span>
                            </div>
                        ) : (
                            ""
                        )}
                    </Swiper>
                </div>
            </section>
        </>
    );
}

export default Slider;
