import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import style css
import "./Footer.scss";
// import footer logo
import footerLogo from "../../assets/triathlonLogos/triathlon-logo.svg";
import footerLogoMobil from "../../assets/triathlonLogos/footerLogoMobil.svg";
import looptechLogo from "../../assets/triathlonLogos/looptech-logo-animated.svg";

// import react icons
import { FiInstagram } from "react-icons/fi";
import { FaFacebook } from "react-icons/fa";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";
import urls from "../../ApiValues/urls";

function Footer() {
    const { lang } = useContext(TrContext);
    const [socialDatas, setSocilaDatas] = useState({});
    const [contactGet, setContactGet] = useState({});

    const getSocialDatas = async () => {
        try {
            const socialDatasRes = await looptech.api().get(urls.social);
            setSocilaDatas(socialDatasRes.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getContactDatas = async () => {
        try {
            const getContactDatasRes = await looptech.api().get(urls.contactGet);
            setContactGet(getContactDatasRes.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSocialDatas();
        getContactDatas();
    }, []);
    // console.log("footer socila datas(links)",socialDatas);
    // console.log("contact get data ", contactGet);

    return (
        <>
            <footer id="footer-section">
                <div className="container">
                    <div className="footer">
                        <a href="/">
                            <img className="web-logo" src={footerLogo} alt="burada footer hissenin logosu var" />
                            <img className="mobil-logo" src={footerLogoMobil} alt="burada footer hissenin logosu var" />
                        </a>
                        <div className="page-list">
                            <ul className="page-list-top">
                                <li>
                                    <Link to={`/${lang}/news`}>{looptech.translate("news")}</Link>
                                </li>
                                <li>
                                    <Link to={`/${lang}/athletes`}>{looptech.translate("athletes")}</Link>
                                </li>
                                <li>
                                    <Link to={`/${lang}/federation`}>{looptech.translate("federation")}</Link>
                                </li>
                            </ul>
                            <ul className="page-list-bottom">
                                <li>
                                    <Link to={`/${lang}/events`}>{looptech.translate("events")}</Link>
                                </li>
                                <li>
                                    <Link to={`/${lang}/results`}>{looptech.translate("results")}</Link>
                                </li>
                                <li>
                                    <Link to={`/${lang}/multimedia`}>{looptech.translate("multimedia")}</Link>
                                </li>
                            </ul>
                        </div>

                        <p className="address">{contactGet["site.address"]}</p>

                        <ul className="call-info">
                            <li>
                                <a href={`tel:${contactGet["site.phone"]}`}> {contactGet["site.phone"]} </a>
                            </li>
                            <li>
                                <a href={`mailto:${contactGet["site.email"]}?subject = Feedback&body = Message`}>{contactGet["site.email"]} </a>
                            </li>
                        </ul>

                        <ul className="social">
                            <li>
                                <a target="_blank" rel="noreferrer" href={socialDatas["site.social_instagram"]}>
                                    <FiInstagram />
                                </a>
                            </li>
                            <li>
                                <a target="_blank" rel="noreferrer" href={socialDatas["site.social_facebook"]}>
                                    <FaFacebook />
                                </a>
                            </li>
                        </ul>

                        <a className="prepared" target="_blank" rel="noreferrer" href="https://www.looptech.az/">
                            <li className="title">{looptech.translate("prepared-site")}</li>
                            <li>
                                <object className="looptech-logo" data={looptechLogo} alt="burada LoopTech logo var" />
                            </li>
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
