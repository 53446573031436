import React, { useState, useEffect, useContext } from "react";
// import style css
import "./Federation.scss";
// import image
import AnimationImage from "../../assets/images/federation-animation-image.png";
// components import
import ClubsSlideComponent from "../../Components/ClubsSliderComponent/ClubsSliderComponent";
import RotateButtonComp from "../../Components/RotateButtonComponent/RotateButtonComp";
import Management from "../../Components/ManagementComponent/Management";
import ExecutiveBoard from "../../Components/ExecutiveBoard/ExecutiveBoard";
import Staff from "../../Components/StaffComponent/Staff";
import LoadingComponent from "../../Components/LoadingComp/LoadingComponent";
import { ScrollToExecutiveBoard, scrollToClubs, scrollToManagement, scrollToReport, scrollToStaff } from "../../Components/Header/header";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";
// import AOS
import Aos from "aos";
import "aos/dist/aos.css";
import urls from "../../ApiValues/urls";

function Federation() {
    const { lang } = useContext(TrContext);
    const [federationPresitentsAbout, setFederationPresitentsAbout] = useState({});
    const [federationPresitentsImg, setFederationPresitentsImg] = useState([]);
    const [management, setManagement] = useState([]);
    const [staff, setStaff] = useState([]);
    const [directors, setDirectors] = useState([]);
    const [clubs, setClubs] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadImages = async (data2, data3, data4, data5, data6) => {
     const data = [AnimationImage,...data2, ...data3,...data4,...data5,...data6]
		const imagePromises = [];
        data.forEach(key => {
            let imageSrc = key.image;
            let imageUrl = (`${looptech.baseUrlImage}${imageSrc}`)  
			const image = new Image();
			const promise = new Promise((resolve, reject) => {
				image.onload = () => resolve(imageUrl);
				image.onerror = () => reject(imageUrl);
			});
			image.src = imageUrl;
			imagePromises.push(promise);
		});
	
		await Promise.all(imagePromises)
			.then(() => {
				console.log('loaded');
			})
			.catch((err) => {
				console.log('Error -- ', err);
			});
    }

    useEffect(() => {
        const FederationSectionGetDatas = async () => {
            try {
                const [data1, data2, data3, data4, data5, data6] = await
                await Promise.all([looptech.api().get(urls.federationPresitentsAbout(lang)),
                        looptech.api().get(urls.federationPresitentsImg(lang)),
                        looptech.api().get(urls.federationManagement(lang)),
                        looptech.api().get(urls.staff(lang)),
                        looptech.api().get(urls.clubs(lang)),
                        looptech.api().get(urls.directors(lang))]);
                setFederationPresitentsAbout(data1.data.data);
                setFederationPresitentsImg(data2.data.data);
                setManagement(data3.data.data);
                setStaff(data4.data.data);
                setClubs(data5.data.data);
                setDirectors(data6.data.data);

                // console.log(data2.data.data);
                // console.log(data3.data.data);
                // console.log(data4.data.data);
                // console.log(data5.data.data);
                // console.log(data6.data.data);
                await loadImages(
                    data2.data.data,
                    data3.data.data,
                    data4.data.data,
                    data5.data.data,
                    data6.data.data
                );
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

        FederationSectionGetDatas();
    }, [lang]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const checkScrollManagement = () => {
        const hasScrollManagement = sessionStorage.getItem("scrollToManagement");
        if (!hasScrollManagement) return;
        setTimeout(() => {
            scrollToManagement();
            sessionStorage.setItem("scrollToManagement", "");
        }, 1200);
    };
    const checkScrollExecutiveBoard = () => {
        const hasExecutiveBoard = sessionStorage.getItem("scrollExecBoard");
        if (!hasExecutiveBoard) return;
        setTimeout(() => {
            ScrollToExecutiveBoard();
            sessionStorage.setItem("scrollExecBoard", "");
        }, 1200);
    };
    const checkScrollStaff = () => {
        const hasScrollStaff = sessionStorage.getItem("scrollStaffElement");
        if (!hasScrollStaff) return;
        setTimeout(() => {
            scrollToStaff();
            sessionStorage.setItem("scrollStaffElement", "");
        }, 1200);
    };

    const checkScrollClubs = () => {
        const hasScrollClubs = sessionStorage.getItem("clubsElement");
        if (!hasScrollClubs) return;
        setTimeout(() => {
            scrollToClubs();
            sessionStorage.setItem("clubsElement", "");
        }, 1200);
    };

    const checkScrollReport = () => {
        const hasScrollReport = sessionStorage.getItem("reportElement");
        if (!hasScrollReport) return;
        setTimeout(() => {
            scrollToReport();
            sessionStorage.setItem("reportElement", "");
        }, 1200);
    };

    useEffect(() => {
        checkScrollManagement();
        checkScrollExecutiveBoard();
        checkScrollStaff();
        checkScrollClubs();
        checkScrollReport();
    }, []);

    const [OpenShowAbout, setOpenShowAbout] = useState(true);
    const funcOpenShowAbout = () => {
        setOpenShowAbout(!OpenShowAbout);
    };
    return (
        <>
            {loading ? (
                <LoadingComponent />
            ) : (
                <section id="federation">
                    <div className="federation-animation">
                        <div className="title-animation">
                            <h2 className="text-uppercase">{looptech.translate("federation")}</h2>

                            <img className="animation-image" src={AnimationImage} loading="lazy"/>
                        </div>
                    </div>
                    {federationPresitentsImg && (
                        <div className="tr-about-federation">
                            <h2 className="text-uppercase">{looptech.translate("about-federation")}</h2>
                            <div onClick={funcOpenShowAbout} className="rotate-btn-component">
                                <RotateButtonComp />
                            </div>
                            {OpenShowAbout && (
                                <div className="container">
                                    <div className="tr-federation">
                                        <div className="federation-presidents">
                                            {federationPresitentsImg.map((item) => (
                                                <div data-aos="fade-up" data-aos-duration="1000" key={item.id} className="about-presidents">
                                                    <img src={`${looptech.baseUrlImage}${item.image}`} alt="burada federation presidentinin sekli var" />
                                                    <div className="overlay">
                                                        <h5>{item.title}</h5>
                                                        <h6>{item.description}</h6>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <p data-aos="fade-up" data-aos-duration="2000" className="about-federation">
                                            {federationPresitentsAbout.body}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <Management managmentDatas={management} />
                    <ExecutiveBoard ExecutiveBoard={directors} />
                    <Staff staffDatas={staff} />

                    {clubs && (
                        <div id="clubs">
                            <h2>{looptech.translate("clubs")}</h2>
                            <div className="container">
                                <ClubsSlideComponent clubsDatas={clubs} />
                            </div>
                        </div>
                    )}
                    <div id="report" data-aos="fade-up" data-aos-duration="2000">
                        <div className="date-annual">
                            <p className="date">2022</p>
                            <p className="annual">{looptech.translate("annualReport")}</p>
                        </div>
                        <a href="" download>
                            {looptech.translate("download")}
                        </a>
                    </div>
                </section>
            )}
        </>
    );
}

export default Federation;
