import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Components/Header/header";
import Footer from "../Components/Footer/Footer";
import { GlobalProvider } from "../TrUseContext/TrUseContext";
import { useParams } from "react-router";

export default function MainLayout() {
    let { locale } = useParams();
    if (["az", "en", "ru"].indexOf(locale) < 0) return window.location.replace(`/${localStorage.getItem("language") || "az"}${window.location.pathname}`);

    return (
        <>
            <GlobalProvider>
                <Header />
                <Outlet />
                <Footer />
            </GlobalProvider>     
        </>
    );
}
