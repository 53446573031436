import React, { useState, useEffect, useContext } from "react";
// import style css
import "./Athletes.scss";
// image import
import athletesSectionAnimationImage from "../../assets/images/athletVeloImage.png";
import RotateButtonComp from "../../Components/RotateButtonComponent/RotateButtonComp";
// import ScrollReveal
import { TrContext } from "../../TrUseContext/TrUseContext";
// import Aos from "aos";
import "aos/dist/aos.css";
import looptech from "../../Helpers/looptech";
import LoadingComponent from "../../Components/LoadingComp/LoadingComponent";
import { Link } from "react-router-dom";

function Athletes() {
    const { lang } = useContext(TrContext);
    const [allAthletesData, setAllAthletesData] = useState([]);
    const [loadingAthlete, setLoadingAthlete] = useState(true);
    const [athleteSectionsVisibility, setAthleteSectionsVisibility] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (allAthletesData.length > 0) {
        }
    }, [allAthletesData]);

    const getAllAthletesData = async () => {
        try {
            const ResAthleteData = await looptech.api().get(`/athlete-categories?lang=${lang}&sort=asc&athletes=1`);
            setAllAthletesData(ResAthleteData.data.data);
            setLoadingAthlete(false);
        } catch (error) {
            console.log(error);
            setLoadingAthlete(false);
        }
    };

    useEffect(() => {
        getAllAthletesData();
    }, [lang]);

    const handleAthleteSection = (id) => {
        setAthleteSectionsVisibility({
            ...athleteSectionsVisibility,
            [id]: athleteSectionsVisibility[id] === false ? true : false,
        });
    };

    return (
        <>
            {loadingAthlete ? (
                <LoadingComponent />
            ) : (
                <section id="athletes">
                    <div className="athletes-header">
                        <div className="athletes-image">
                            <h2 className="text-uppercase">{looptech.translate("athletes")}</h2>
                            <img className="image-atletes" src={athletesSectionAnimationImage} loading="lazy" alt="burada athletes sectionunun animation shekli var" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="tr-athletes">
                            {allAthletesData.map((athletesCategoryDatas) => (
                                <div key={athletesCategoryDatas.id}>
                                    <div className="tr-athlets-OpenClose">
                                        <h2 className="text-uppercase">{athletesCategoryDatas.title}</h2>
                                        <div className="OpenClose" onClick={() => handleAthleteSection(athletesCategoryDatas.id)}>
                                            <RotateButtonComp />
                                        </div>
                                    </div>
                                    {athletesCategoryDatas.type === 1 && !(athleteSectionsVisibility[athletesCategoryDatas.id] === false) && (
                                        <div className={`atheletes-list teenage-athletes ${athleteSectionsVisibility[athletesCategoryDatas.id] === false ? "hidden" : ""}`}>
                                            {athletesCategoryDatas.athletes.map((athlet) => (
                                                <Link to={`/athletes/${athlet.slug}`} key={athlet.slug} className="teenage athlete-item">
                                                    <img src={`${looptech.baseUrlImage}${athlet.image}`} alt={athlet.title} loading="lazy" />
                                                    <h6>{athlet.title}</h6>
                                                    <span>{athlet.birthday}</span>
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                    {athletesCategoryDatas.type === 2 && !(athleteSectionsVisibility[athletesCategoryDatas.id] === false) && (
                                        <div className={`atheletes-list young-menWomen-athlete min-container  ${athleteSectionsVisibility[athletesCategoryDatas.id] === false ? "hidden" : ""} `}>
                                            {athletesCategoryDatas.athletes.map((item) => (
                                                <Link key={item.slug} to={`/athletes/${item.slug}`} className="young-menWomen athlete-item">
                                                    <img loading="lazy" src={`${looptech.baseUrlImage}${item.image}`} alt={item.title} />
                                                    <div className="young-menWomen-athlete-datas">
                                                        <h6>{item.title}</h6>
                                                        <span>{item.birthday}</span>
                                                        {item.description && <p>{item.description}</p>}
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default Athletes;
