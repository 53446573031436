import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import navLogo from "../../assets/triathlonLogos/navLogo.svg";
// import react icons
import { GrLanguage } from "react-icons/gr";
import { GrFormClose } from "react-icons/gr";
import { TbPointFilled } from "react-icons/tb";
import "./header.scss";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";

export const scrollToManagement = () => {
    const managementElement = document.getElementById("management");
    if (!managementElement) {
        sessionStorage.setItem("scrollToManagement", "true");
        return (window.location.href = "/federation");
    }
    managementElement.scrollIntoView({ behavior: "smooth", block: "start" });
};

export const ScrollToExecutiveBoard = () => {
    const ExecutiveBoard = document.getElementById("executiveBoard");
    if (!ExecutiveBoard) {
        sessionStorage.setItem("scrollExecBoard", "true");
        return (window.location.href = "/federation");
    }
    ExecutiveBoard.scrollIntoView({ behavior: "smooth", block: "start" });
};

export const scrollToStaff = () => {
    const staffElemet = document.getElementById("staff");
    if (!staffElemet) {
        sessionStorage.setItem("scrollStaffElement", "true");
        return (window.location.href = "/federation");
    }
    staffElemet.scrollIntoView({ behavior: "smooth", block: "start" });
};

export const scrollToClubs = () => {
    const clubsElement = document.getElementById("clubs");
    if (!clubsElement) {
        sessionStorage.setItem("clubsElement", "true");
        return (window.location.href = "/federation");
    }
    clubsElement.scrollIntoView({ behavior: "smooth", block: "start" });
};

export const scrollToReport = () => {
    const reportElement = document.getElementById("report");

    if (!reportElement) {
        sessionStorage.setItem("reportElement", "true");
        return (window.location.href = "/federation");
    }
    reportElement.scrollIntoView({ behavior: "smooth", block: "start" });
};

let langTimeout;
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function Header() {
    const { lang, changeLanguage } = useContext(TrContext);
    const [selectedLang, setSelectedLang] = useState(lang);

    useEffect(() => { 
        setSelectedLang(lang);
    }, [lang]);

    const handleLanguage = (l = "az") => {
        clearTimeout(langTimeout);
        setSelectedLang(l);
        langTimeout = setTimeout(() => {
            changeLanguage(l.toLowerCase());
        }, 300);
    };

    const [hamburgerMenu, setHamburgerMenu] = useState(false);

    const showMenu = () => {
        setHamburgerMenu(!hamburgerMenu);
    };
    
    return (
        <>
            <section id="header">
                <div className="container">
                    <header>
                        <a href="/" className="nav-logo">
                            <img src={navLogo} alt="burada triathlonLogo var" />
                        </a>
                        <div onClick={showMenu} className="hamburger-menu">
                            {hamburgerMenu ? (
                                <GrFormClose className="icon-close" />
                            ) : (
                                <span className="">
                                    <TbPointFilled /> <TbPointFilled /> <TbPointFilled />
                                </span>
                            )}{" "}
                        </div>
                        <nav>
                            <ul className="pages-list">
                                <li>
                                    <NavLink
                                        style={({ isActive }) => {
                                            return isActive ? { color: "red" } : {};
                                        }}
                                        to={`/${lang}/news`}
                                    >
                                        {looptech.translate("news")}
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        style={({ isActive }) => {
                                            return isActive ? { color: "red" } : {};
                                        }}
                                        to={`/${lang}/events`}
                                    >
                                        {looptech.translate("events")}
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        style={({ isActive }) => {
                                            return isActive ? { color: "red" } : {};
                                        }}
                                        to={`/${lang}/results`}
                                    >
                                        {looptech.translate("results")}
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        style={({ isActive }) => {
                                            return isActive ? { color: "red" } : {};
                                        }}
                                        to={`/${lang}/athletes`}
                                    >
                                        {looptech.translate("athletes")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        style={({ isActive }) => {
                                            return isActive ? { color: "red" } : {};
                                        }}
                                        to={`/${lang}/multimedia`}
                                    >
                                        {looptech.translate("multimedia")}
                                    </NavLink>
                                </li>
                                <li className="federation">
                                    <NavLink
                                        style={({ isActive }) => {
                                            return isActive ? { color: "red" } : {};
                                        }}
                                        to={`/${lang}/federation`}
                                    >
                                        {looptech.translate("federation")}
                                    </NavLink>
                                    <ul className="drop-down-menu">
                                        <li>
                                            <NavLink onClick={scrollToManagement}>{looptech.translate("ExecutiveCommittee")}</NavLink>
                                        </li>
                                        <li>
                                            <NavLink onClick={ScrollToExecutiveBoard}>{looptech.translate("ExecutiveBoard")}</NavLink>
                                        </li>

                                        <li>
                                            <NavLink onClick={scrollToStaff}>{looptech.translate("Coaches")}</NavLink>
                                        </li>
                                        <li>
                                            <NavLink onClick={scrollToClubs}>{looptech.translate("clubs")}</NavLink>
                                        </li>
                                        <li>
                                            <NavLink onClick={scrollToReport}>{looptech.translate("annualReport")}</NavLink>
                                        </li>
                                    </ul>
                                </li>     
                                <li>
                                    <NavLink
                                        to={`/${lang}/registration`}
                                        style={({ isActive }) => {
                                            return isActive ? { color: "red" } : {};
                                        }}
                                    >
                                        {looptech.translate("registration")}
                                    </NavLink>
                                </li> 
                                <li>
                                    <NavLink
                                        to={`/${lang}/contact`}
                                        style={({ isActive }) => {
                                            return isActive ? { color: "red" } : {};
                                        }}
                                    >
                                        {looptech.translate("contact")}
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>

                        <ul className="languages">
                            <span>
                                <GrLanguage />
                            </span>
                            {looptech.langsArr.map((item) => {
                                return (
                                    <li key={item} onClick={() => handleLanguage(item)} className={`${selectedLang === item ? "active-lang" : "noActive-lang"}`}>
                                        {capitalizeFirstLetter(item)}
                                    </li>
                                );
                            })}
                        </ul>
                    </header>

                    <div className={hamburgerMenu ? "hamburger-menu-list" : "hamburger-menu-list noActive"}>
                        <ul className="pages-list">
                            <li>
                                <Link onClick={showMenu} to={`/${lang}/news`}>
                                    {looptech.translate("news")}
                                </Link>
                            </li>
                            <li>
                                <Link onClick={showMenu} to={`/${lang}/events`}>
                                    {looptech.translate("events")}
                                </Link>
                            </li>
                            <li>
                                <Link onClick={showMenu} to={`/${lang}/results`}>
                                    {looptech.translate("results")}
                                </Link>
                            </li>
                            <li>
                                <Link onClick={showMenu} to={`/${lang}/athletes`}>
                                    {looptech.translate("athletes")}
                                </Link>
                            </li>
                            <li>
                                <Link onClick={showMenu} to={`/${lang}/multimedia`}>
                                    {looptech.translate("multimedia")}
                                </Link>
                            </li>
                            <li>
                                <Link onClick={showMenu} to={`/${lang}/federation`}>
                                    {looptech.translate("federation")}
                                </Link>
                            </li>
                            <li>
                                <Link onClick={showMenu} to={`/${lang}/registration`}>
                                    {looptech.translate("registration")}
                                </Link>
                            </li>
                            <li>
                                <Link onClick={showMenu} to={`/${lang}/contact`}>
                                    {looptech.translate("contact")}
                                </Link>
                            </li>
                        </ul>
                        <ul className="languages-mobil">
                            {looptech.langsArr.map((item) => {
                                return (
                                    <li key={item} onClick={() => handleLanguage(item)} className={`${selectedLang === item ? "active-lang" : "noActive-lang"}`}>
                                        {item}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Header;
