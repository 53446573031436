import { useContext, useEffect } from "react";
// import style css
import "./Muslitmedia.scss";
import { Link } from "react-router-dom";
// import ScrollReveal
import ScrollReveal from "scrollreveal";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";
import { useState } from "react";
import urls from "../../ApiValues/urls";
import LoadingComponent from "../../Components/LoadingComp/LoadingComponent";

function Multimedia() {
    const { lang } = useContext(TrContext);
    const [multiData, setMultiData] = useState([]);
    const [multiLoading, setMultiLoading] = useState(true);

     const loadImages = async (data) => {
		const imagePromises = [];
        data.forEach(key => {
            let imageSrc = key.cover_image;
            let imageUrl = (`${looptech.baseUrlImage}${imageSrc}`)
			const image = new Image();
			const promise = new Promise((resolve, reject) => {
				image.onload = () => resolve(imageUrl);
				image.onerror = () => reject(imageUrl);
			});
			image.src = imageUrl;
			imagePromises.push(promise);
		});
	
		await Promise.all(imagePromises)
			.then(() => {
				console.log('loaded');
			})
			.catch((err) => {
				console.log('Error -- ', err);
			});
    }


    const getMultiDatas = async () => {
        try {
            const multiDataRes = await looptech.api().get(urls.multimedia(lang));
            setMultiData(multiDataRes.data.data.data);
            console.log(multiDataRes.data.data.data)
            await loadImages(multiDataRes.data.data.data);
            setMultiLoading(false);
        } catch (error) {
            console.log(error);
            setMultiLoading(false);
        }
    };
    useEffect(() => {
        getMultiDatas();
    }, [lang]);


    // console.log("multimedia datas ", multiData);   

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        ScrollReveal().reveal(".tr-media", {
            interval: 1000,
        });
    }, []);

    return (
        <>
            {multiLoading ? (
                <LoadingComponent />
            ) : (
                <section id="section-multimedia">
                    <div className="container">
                        <h2 className="text-uppercase">{looptech.translate("multimedia")}</h2>
                        <div className="multimedia">
                            {multiData.map((item) => (
                                <div key={item.id} className="tr-media">
                                    <img src={`${looptech.baseUrlImage}${item.cover_image}`} loading="lazy"  />
                                    <Link to={`/multimedia/${item.slug}`} className="overlay">
                                        <h5>{item.title}</h5>
                                        <span>{looptech.translate("readMore")}</span>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default Multimedia;
