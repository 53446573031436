import React, { useState, useEffect, useContext } from "react";
// import { StuffDatas } from '../../MyWriteSiteData/data'
import ManagementAndStaffComponent from "../ManagementAndStaffComponent/ManagementAndStaffComponent";
import RotateButtonComp from "../RotateButtonComponent/RotateButtonComp";
// import style css
import "./Staff.scss";
// import ScrollReveal
import ScrollReveal from "scrollreveal";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";

function Staff({ staffDatas }) {
    useContext(TrContext);
    const [OpenShowStaff, setOpenShowStaff] = useState(true);

    const funcOpenShowStaff = () => {
        setOpenShowStaff(!OpenShowStaff);
    };

    useEffect(() => {
        ScrollReveal().reveal(".stuff", {
            interval: 300,
        });
    }, []);
    if (!staffDatas) return;
    return (
        <>
            <div id="staff">
                <h2>{looptech.translate("Coaches")}</h2>
                <div onClick={funcOpenShowStaff} className="rotate-btn-component">
                    <RotateButtonComp />
                </div>
                {OpenShowStaff && (
                    <div className="tr-staff">
                        {staffDatas.map((item) => (
                            <div key={item.id} className="stuff">
                                <ManagementAndStaffComponent keys={item.id} image={`${looptech.baseUrlImage}${item.image}`} name={item.title} position={item.position} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}

export default Staff;
