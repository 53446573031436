import React, { useEffect, useContext } from "react";
// import style scss
import "./Registration.scss";
// import useFormik
import { useFormik } from "formik";
// import yup
import * as Yup from "yup";

// import sweetalert2
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";
import urls from "../../ApiValues/urls";

function Registration() {
    useContext(TrContext);

    const validationSchema = Yup.object({
        firstname: Yup.string().required("name-error"),
        lastname: Yup.string().required("lastName-error"),
        birthday: Yup.string().required("birthday-error"),
        email: Yup.string().email().required("email-error"),
        number: Yup.string().required("phone-error"),
        message: Yup.string().required("textarea-error"),
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { handleChange, values, handleSubmit, errors, resetForm } = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            birthday: "",
            email: "",
            number: "",
            message: "",
        },
        onSubmit: async (values) => {
            try {
                await looptech.api().post(urls.register, JSON.stringify(values));
                const MySwal = withReactContent(Swal);
                MySwal.fire({
                    title: <strong>{looptech.translate("swalAlertTitle")}</strong>,
                    html: <i>{looptech.translate("swalAlertHTML")}</i>,
                    icon: "success",
                });
            } catch (error) {
                console.log(error);
                alert("error var !!!");
            }
            resetForm();  
        },
        validationSchema,
    });
    return (
        <section id="registration">
            <div className="container">
                <div className="form-group">
                    {/* cemiponat vaxti gosterilmeli olan basliq */}
                    {/* <h2 className="text-uppercase registr-title register">{looptech.translate("Az-championship")}</h2> */}
                    {/* adi formada olan basliq*/}
                    <h2 className="text-uppercase registr-title register">{looptech.translate("registration")}</h2>
                    <form onSubmit={handleSubmit}>
                        <input name="firstname" type="text" placeholder={looptech.translate("firstName")} value={values.firstname} onChange={handleChange} />
                        <div className="error">{errors.firstname ? looptech.translate(errors.firstname) : null}</div>

                        <input name="lastname" type="text" placeholder={looptech.translate("lastName")} value={values.lastname} onChange={handleChange} />
                        <div className="error">{errors.lastname ? looptech.translate(errors.lastname) : null}</div>
                        <input name="birthday" type="date" placeholder={values.birthday ? "" : looptech.translate("birthday")} className={values.birthday ? "noWidth" : "dateInput"} value={values.birthday} onChange={handleChange} />

                        <div className="error">{errors.birthday ? looptech.translate(errors.birthday) : null}</div>

                        <input name="email" type="email" placeholder={looptech.translate("email")} value={values.email} onChange={handleChange} />
                        <div className="error">{errors.email ? looptech.translate(errors.email) : null}</div>
                        <input name="number" type="tel" placeholder={looptech.translate("phone")} value={values.number} onChange={handleChange} />
                        <div className="error">{errors.number ? looptech.translate(errors.number) : null}</div>

                        <textarea name="message" rows="3" placeholder={looptech.translate("message")} value={values.message} onChange={handleChange}></textarea>
                        <div className="error error-textarea">{errors.message ? looptech.translate(errors.message) : null}</div>
                        <button type="submit">{looptech.translate("send")}</button>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default Registration;
